a.nav-link.active{
    border-radius: 0.2rem;
    border-left: 5px solid #48bbc6 !important;
    padding-left: 12px !important;
}

.sidebar-link > a:hover, .sidebar-link > a{
    color: #21606a;
}

.sidebar-link i{
    color: #8994a3;
}

.sidebar-link .active i{
    color: #48bbc6;
}

.sidebar-link a{
    color: #8994a3;
}

.sidebar-link .active {
    color: #21606a;
}

.slide-in-left {
    -webkit-animation: slide-in-left 0.5s both;
    animation: slide-in-left 0.5s both;
}

.circle-icon {
    background: #A4B2B9;
    padding:10px;
    border-radius: 50%;
}

nav.toggled div[class*="label"] {
    display: none;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-2-6 8:30:42
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-300px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-300px);
        transform: translateX(-300px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

.slide-out-left {
    -webkit-animation: slide-out-left 0.5s ease-in both;
    animation: slide-out-left 0.5s ease-in both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-2-6 8:37:5
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(-300px);
        transform: translateX(-300px);
        opacity: 0;
    }
}

@keyframes slide-out-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateX(-300px);
        transform: translateX(-300px);
        opacity: 0;
    }
}
