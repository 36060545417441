button {
    border-style: solid;
}

button:focus {
    outline: none;
}
.btn-gray {
    color: #ffffff;
    background-color: #808E97;
    border-color: #808E97;
}

.btn-file {
    position: relative;
    overflow: hidden;
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}
