.fw100{
    font-weight: 100;
}

.fw200{
    font-weight: 200;
}

.fw300{
    font-weight: 300;
}

.fw400{
    font-weight: 400;
}

.fw500{
    font-weight: 500;
}

.fw600{
    font-weight: 600;
}

.fw700{
    font-weight: 700;
}

.fw800{
    font-weight: 800;
}

.fw900{
    font-weight: 900;
}

.fblack{
    color: #000 !important;
}

.fred {
    color: #ff0000 !important;
}

.fgrey{
    color: #8994a3;
}

.factive{
    color: #48bbc6;
}

.fselected {
    color: #21606a;
}

.fwhite {
    color: white;
}

.fsmall {
    font-size: small;
}

.fsmaller {
    font-size: smaller;
}

.fxsmall {
    font-size: x-small;
}

.fxxsmall {
    font-size: xx-small;
}

.flarge {
    font-size: large;
}

.flarger {
    font-size: larger;
}

.mark, mark {
    padding: 0 !important;
}