.sidebar .nav-item .nav-link span {
    margin-left: 3px !important;
}

.sidebar-menu {
    background: #ffffff;
}

.form-inline .form-group {
    margin-bottom: 5px;
}

.sidebar .nav-link:hover {
    border-left: 1px #fff solid;
    background: rgba(0, 0, 0, 0.1);
}

.sidebar .nav-link {
    border-left: 1px rgba(0, 0, 0, 0) solid;
}

.sidebar-divider {
    color: #fff;
    width: 70%;
    margin-left: 11px;
}

.theme-form-control {
    margin: 0px 8px 0px 6px;
}

.btn-sm {
    font-size: 0.7rem !important;
}

.dataTable-filters {
    font-size: 0.8rem !important;
}

.pointer {
    cursor: pointer;
}

.zi1 {
    z-index: 1 !important;
}

.zi2 {
    z-index: 2 !important;
}

.noDecor, .noDecor:visited, .noDecor:active, .noDecor:hover {
    text-decoration: none;
}

/*.rw-widget-picker {
    height: auto !important;
    width: fit-content !important;
}*/

.apexcharts-toolbar {
    top: unset !important;
}

.bgr-white {
    background-color: white !important;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
}

.bgr-black {
    background-color: black !important;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
}

.bgr-active {
    background-color: #48bbc6 !important;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
}

.bgr-grey {
    background-color: #e8eef5 !important;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
}

.bgr-low {
    background-color: #48bbc6 !important;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
}

.bgr-mid {
    background-color: #3097a2 !important;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
}

.bgr-high {
    background-color: #21606a !important;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
}

.fitter-datetime-picker .rw-popup-container {
    position: relative !important;
}

@media print {

    .bgr-white {
        background-color: white !important;
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;
    }

    .bgr-black {
        background-color: black !important;
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;
    }

    .bgr-active {
        background-color: #48bbc6 !important;
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;
    }

    .bgr-grey {
        background-color: #e8eef5 !important;
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;
    }

    .bgr-low {
        background-color: #48bbc6 !important;
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;
    }

    .bgr-mid {
        background-color: #3097a2 !important;
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;
    }

    .bgr-high {
        background-color: #21606a !important;
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;
    }

}

.flarge{
    padding-bottom: 15px;
}

label {
    margin-bottom: 0 !important;
}